import { PhotoAlbum } from "react-photo-album"
import { Container } from "reactstrap"

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";
import { useState } from "react";
import { Nav } from "react-bootstrap";

export const Product = () => {
    const [index, setIndex] = useState(-1);

    const photos = [
        { src: "/img/model1.jpeg", width: 1066, height: 1600, href: "#", title: "Title 1", description: "Description 1 \n break test" },
        { src: "/img/about.jpeg", width: 1600, height: 900, href: "#", title: "Title 2", description: "Description 2" },
        { src: "/Front.jpeg", width: 1600, height: 1064, href: "#", title: "Title 3", description: "Description 3" },
        { src: "/img/model2.jpeg", width: 1066, height: 1600, href: "#" },
        // { src: "/img/FCT-Model.jpeg", width: 1066, height: 1600, href: "#" },
        // { src: "/img/model3.jpeg", width: 1066, height: 1600, href: "#" },
        // { src: "/Front.jpeg", width: 1600, height: 1064, href: "#" },
    ]

    return (
        <Container className="mt-3 mb-3">
            <div className="row">
                <div className="col-md-8">
                    <PhotoAlbum columns={2} layout="columns" photos={photos} onClick={({ index }) => setIndex(index)} />
                    {/* <ConstructionProgress /> */}

                </div>
                <Lightbox
                    slides={photos}
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    // enable optional lightbox plugins
                    plugins={[Thumbnails, Zoom, Captions]}
                />
                <div className="col-md-4">
                    <h4>Брюки укороченные</h4>
                    <div><strong>15 000 ₽</strong></div>
                    <div class="form-floating mt-1">
                        <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                            <option selected disabled hidden>--- Выберите размер ---</option>
                            <option value="1">S</option>
                            <option value="2">M</option>
                            <option value="3">XL</option>
                        </select>
                        <label for="floatingSelect">Размер</label>
                    </div>
                    <div className="mt-3">Артикул: 0000001</div>
                    <Nav className="flex-column">
                        <Nav.Link className="border-bottom">Active</Nav.Link>
                        <Nav.Link className="border-bottom">Link</Nav.Link>
                        <Nav.Link className="border-bottom">Link</Nav.Link>
                    </Nav>
                </div>
            </div>
        </Container>
    )

}