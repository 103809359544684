import React from 'react';


export const Home = () => {
  return (
    <div>
      <div className="cover">
        <div className='cover-img' style={{ backgroundImage: `url("/Front.jpeg")` }}>
          {/* <div className='cover-content align-self-center'>
            <div className='cover-title'>
              Заголовок
            </div>
            <div className='cover-description'>
              композиционная форма, 
              которую используют в 
              литературоведении и 
              лингвистике для подробной 
              характеристики предметов
              или явлений в целях создания художественного образа.
            </div>
          </div> */}

        </div>
        <div id="container-front">
          Make
          <div style={{ fontSize: "50px" }} id="flip">
            <div><div>REACTION</div></div>
            <div><div>CREATIVE</div></div>
            <div><div>FASHION</div></div>
          </div>
          AweSoMe!
        </div>
      </div>
      {/* <div style={{ height: "100vh" }}></div> */}
      <div className='pb-5' style={{ backgroundImage: `url("img/about.jpeg")`, backgroundRepeat: "no-repeat", backgroundAttachment: "fixed", backgroundPosition: "center", backgroundSize: "cover" }}>
        {/* <div style={{ minHeight: "150px" }}></div> */}
        <div className='container'>
          <div style={{ color: "#fff" }} className='pt-5 d-none d-md-block'>
            <div><h1>FASHION</h1></div>
            <div><h1>CREATIVE</h1></div>
            <div><h1>REACTION</h1></div>
          </div>
          <div className='row'>
            <div className='col'>
              <svg className='img-fluid' style={{ fill: "#fff" }}>
                <use xlinkHref='logo.svg#logo'></use>
              </svg>
            </div>
            <div className='col-8'>
              <h1 className='flex-fill align-self-center ps-2 text-wrap mt-3' style={{ color: "#fff" }}>на Санкт-Петербургской {"\n"} неделе моды</h1>
            </div>
          </div>
        </div>
        {/* <div style={{ minHeight: "100vh" }}> */}
        {/* <img src='img/about.jpeg' className='img-fluid' /> */}
        <div className='container' style={{ backgroundColor: "#fff" }}>
          <div className='row pt-5'>
            <div className='col-md-6 col-lg-5 offset-lg-1'>
              <p><strong>FCR</strong> - представляет на Петербургской неделе моды свою первую коллекцию WE/МЫ,
                созданную людьми, чья профессиональная творческая деятельность направлена на погружение в мир эмоций.
                Профессиональный Дизайнер создает креативный образ, отталкиваясь от внешнего.
                FCR свою одежду прорабатывает от внутренних ощущений, «проживая изнутри».
              </p>
            </div>
            <div className='col-md-6 col-lg-5'>
              <p>
                <strong>Концепция бренда</strong> – соединение капсульных коллекций, включающих в себя театральный шик и
                базовые потребности современного общества - цвет, стиль, комфорт и красота.
                FCR - это возможность примерить новый образ, не изменяя себе.
              </p>
            </div>
          </div>
          <div className='row ps-lg-5 pe-lg-5 mb-5'>
            <div className='col-md'>
              <div>
                <img />
              </div>
              <p className='text-wrap' style={{ textAlign: "justify" }}>
                <strong>Руденко Владислав</strong> - танцовщик хореограф-постановщик, ди-джей:
                «Мне важно, как танцору, чтобы вещь была удобной и стильной, а главное свободной. Что б я мог в ней и выйти на улицу, и потренироваться. И конечно сильное влияние 90х – спортивной одежды, которая мне нравилась на моих родителях.
              </p>
            </div>
            <div className='col-md'>
              <div>
                <img />
              </div>
              <p className='text-wrap' style={{ textAlign: "justify" }}>
                <strong>Феликс Михайлов</strong> – идеолог бренда, театральный и телевизионный режиссер, арт-директор театра Ленинград Центр, продюсер; «В напряженное время, хочется предложить людям добавить в свой образ игры, ярких красок и немного театральности. Вся наша жизнь некая игра»!
              </p>
            </div>
            <div className='col-md'>
              <div>
                <img />
              </div>
              <p className='text-wrap' style={{ textAlign: "justify" }}>
                <strong> Салаватов Константин</strong> -  выпускник Академии театрального искусства, уже более 10 лет создает костюмы для крупных проектов таких как церемонии чемпионата мира ФИФА 2018, Ледовые шоу, шоу театра «Ленинград Центр».
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>


  );
}