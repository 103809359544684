import React from "react";
import classes from './LoadScreen.module.css';

export const LoadScreen = (props) => {
    // if (props?.fullScreen) {
    //     return (
    //         <div className="cover">
    //             <div id="container-loading" style={{ color: "#000" }}>
    //                 Make
    //                 <div id="flip-loading">
    //                     <div><div>FASHION</div></div>
    //                     <div><div>CREATIVE</div></div>
    //                     <div><div>REACTION</div></div>
    //                 </div>
    //                 AweSoMe!
    //                 <div className="d-flex justify-content-center align-content-center">
    //                     {/* <div className={classes.loadArrow}></div> */}
    //                 </div><span className={classes.textLoading}>Loading...</span>
    //             </div>

    //         </div>
    //     )
    // }
    return (
        <div className={`d-flex flex-column justify-content-center align-content-center ${classes.loadBody}`} style={{ minHeight: '100px', height: props?.height + "vh" }}>
            <div className="d-flex justify-content-center align-content-center">
                <svg className='img-fluid' height="60px" width="230px" style={{ fill: "#000" }}>
                    <use xlinkHref='logo.svg#logo'></use>
                </svg>
            </div>
            <div className="d-flex justify-content-center align-content-center">
                <div className={classes.loadArrow}></div>
            </div><span className={classes.textLoading}>Loading...</span>
        </div>

    )
}

export default { LoadScreen }