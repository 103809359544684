import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './custom.css';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import useToken from './features/useToken'
import { LoadScreen } from './components/UI/LoadScreen'
import { LifeStyle } from './components/LifeStyle';
import { ShopHome } from './components/Shop/ShopHome';
import { LayoutShop } from './components/LayoutShop';
import { Product } from './components/Shop/Product/Product';


export const App = () => {
  const { token, setTokenData, loadingSite, role, getRefreshToken, fullName } = useToken();

  // return (
  //   <LoadScreen fullScreen />
  // );
  if (loadingSite) {
    return (
      <LoadScreen />
    );
  }
  return (
    <Routes>
      <Route path='' element={<Layout token={token} role={role} getRefreshToken={getRefreshToken} />}>
        <Route index element={<Home />} />
        <Route path='shop' element={<ShopHome />} />
      </Route>
      <Route path='lifestyle' element={<LayoutShop token={role} role={role} getRefreshToken={getRefreshToken} />}>
        <Route index element={<LifeStyle />} />
        <Route path='product' element={<Product />} />
      </Route>
      <Route path='shop' element={<LayoutShop token={role} role={role} getRefreshToken={getRefreshToken} />}>
        <Route path='lifestyle' element={<LifeStyle />} />
        <Route path='product' element={<Product />} />
      </Route>
    </Routes>
  );
}


export default App;