import { useNavigate } from "react-router-dom"

export const ProductCard = () => {

    const navigate = useNavigate();

    return (
        // <div style={{ width: "300px", padding: "10px" }}>
        <div style={{cursor: "pointer"}} onClick={() => {navigate("/shop/product")}}>
            <div className="text-center">
                <img src="/NoPhoto.png" className="img-fluid border m-auto" />
            </div>
            <div className="text-center">
                Название
            </div>
        </div>
    )
}