import React, { Component } from 'react';
import { Outlet } from 'react-router-dom';
import { NavMenuShop } from './NavMenuShop';
import { Footer } from './Footer';

export const LayoutShop = () => {
    return (
        <div>
            <NavMenuShop />
            <Outlet />
            <Footer />
        </div>
    );
}
