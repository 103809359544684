import React, { useEffect, useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import './NavMenu.css';

export const NavMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [bgNav, setBgNav] = useState("nav-bg-black navbar-dark")
  const [logoColor, setLogoColot] = useState("logo-white")
  const navigate = useNavigate();
  const loaction = useLocation();
  // console.log(loaction)

  useEffect(() => {
    if ((loaction.pathname == "/" || loaction.pathname == "/shop")) {
      const scrollHandeler = (e) => {
        if (e.target.documentElement.scrollTop > 15) {
          setBgNav("nav-bg-white")
          setLogoColot("logo-black")
        }
        else {
          setBgNav("nav-bg-black navbar-dark")
          setLogoColot("logo-white")
        }
        // if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 1) {
        //   console.log("loading...")
        // }
      }
      document.addEventListener('scroll', scrollHandeler)
      return function () {
        document.removeEventListener('scroll', scrollHandeler)
      }
    }
  }, [loaction])
  useEffect(() => {
    // console.log(loaction.pathname)
    if ((loaction.pathname != "/" && loaction.pathname != "/shop")) {
      setBgNav("nav-bg-white")
      setLogoColot("logo-black")
    }
    else {
      setBgNav("nav-bg-black navbar-dark")
      setLogoColot("logo-white")
    }
  }, [loaction]
  )

  return (
      <Navbar className={`navbar-expand-sm navbar-toggleable-sm  box-shadow fixed-top transition-nav ${bgNav}`} container>
        <NavbarBrand onClick={() => navigate("/")} href='#'>
          <div className={logoColor}>
            <svg className='img-fluid' style={{ maxWidth: "100px", maxHeight: "40px" }}>
              <use xlinkHref='logo.svg#logo'></use>
            </svg>
          </div>
        </NavbarBrand>
        <NavbarToggler className="mr-2" onClick={toggle} />
        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={isOpen} navbar>
          <ul className="navbar-nav flex-grow">
            <NavItem>
              <NavLink onClick={() => {
                navigate("/")
                setIsOpen(false)
              }} href='#'>Проект</NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={
                () => {
                  navigate("/lifestyle")
                  setIsOpen(false)
                }
              } href='#'>Стиль в жизни</NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={
                () => {
                  navigate("/shop")
                  setIsOpen(false)
                }
              } href='#'>Магазин</NavLink>
            </NavItem>
          </ul>
        </Collapse>
      </Navbar>
  );
}
