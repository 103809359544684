import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selected_job: null,
    selected_room: null,
    selected_device: null
}

const selectionSlice = createSlice({
    name: "selection",
    initialState,
    reducers: {
        reset: (state) => initialState,
        setSelectedJob: (state, action) =>
        {
            state.selected_job = action.payload
        },
        setSelectedRoom: (state, action) => {
            state.selected_room = action.payload
        },
        setSelectedDevice: (state, action) => {
            state.selected_device = action.payload
        }
    }
})

export const { reset, setSelectedJob, setSelectedRoom, setSelectedDevice } = selectionSlice.actions

export default selectionSlice.reducer