import { useNavigate } from "react-router-dom"
import { Container } from "reactstrap"
import { format } from "date-fns"

export const Footer = () => {

    const navigate = useNavigate();

    return (
        <footer className="fcr-footer pt-3 pb-3 text-white">
            <Container className="">
                <div className="row">
                    <div className="col g-1 text-center">
                        <div>
                            <svg className='img-fluid' style={{ maxWidth: "100px", maxHeight: "40px", fill: "#fff" }}>
                                <use xlinkHref='logo.svg#logo'></use>
                            </svg>
                            <div className="text-white">FASHION</div>
                            <div className="text-white">CREATIVE</div>
                            <div className="text-white">REACTION</div>
                        </div>
                    </div>
                    <div className="col g-1 navbar-dark fcr-footer-menu">
                        <ul className="nav flex-column navbar-dark">
                            <li className="nav-item">
                                <a className="nav-link" href="#">Link</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Link</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Link</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col g-1 navbar-dark fcr-footer-menu d-none d-md-block">
                        <ul className="nav flex-column navbar-dark">
                            <li className="nav-item">
                                <a className="nav-link" href="#">Link</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Link</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Link</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="text-center mt-3">FCR ©  {format(new Date(), "yyyy")}</div>
            </Container>
        </footer>
    )

}