import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Grid, FreeMode } from 'swiper/modules';
import { ProductCard } from './ProductCard';


export const ProductSeasonSwiper = () => {
    return (
        <>
            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                freeMode={true}
                speed={1300}
                loopedSlides={3}
                // slidesPerGroup={2}
                // grid={{ rows: 2, fill: "row" }}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        loopedSlides: 1,
                        spaceBetween: 10,
                        // grid: { rows: 1 },
                        freeMode: false
                    },
                    320: {
                        slidesPerView: 2,
                        loopedSlides: 2,
                        spaceBetween: 10,
                        // grid: { rows: 1 }
                    },
                    800: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 10,
                        // grid: { rows: 1 }
                    },
                    920: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                        spaceBetween: 10,
                        // grid: { rows: 2 }

                    },
                    1180: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        spaceBetween: 10,
                        // grid: { rows: 2 }

                    }
                }}

                modules={[Autoplay, Navigation, Grid, FreeMode]}
                // loop={true}
                navigation={true}
            // autoplay={{ delay: 10000 }}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            >
                <SwiperSlide>
                    <ProductCard />
                </SwiperSlide>
                <SwiperSlide>
                    <ProductCard />
                </SwiperSlide>
                <SwiperSlide>
                    <ProductCard />
                </SwiperSlide>
                <SwiperSlide>
                    <ProductCard />
                </SwiperSlide>
                <SwiperSlide>
                    <ProductCard />
                </SwiperSlide>
                <SwiperSlide>
                    <ProductCard />
                </SwiperSlide>
                <SwiperSlide>
                    <ProductCard />
                </SwiperSlide>
                <SwiperSlide>
                    <ProductCard />
                </SwiperSlide>
                <SwiperSlide>
                    <ProductCard />
                </SwiperSlide>
                <SwiperSlide>
                    <ProductCard />
                </SwiperSlide>
                <SwiperSlide>
                    <ProductCard />
                </SwiperSlide>
                <SwiperSlide>
                    <ProductCard />
                </SwiperSlide>
                <SwiperSlide>
                    <ProductCard />
                </SwiperSlide>
                <SwiperSlide>
                    <ProductCard />
                </SwiperSlide>
                <SwiperSlide>
                    <ProductCard />
                </SwiperSlide>
            </Swiper>
        </>
    )
}