import { Button, ButtonGroup, Container } from "reactstrap"
import { ProductSeasonSwiper } from "./ProductSeasonSwiper"
import { ShopMainCarusel } from "./ShopMainCarusel"
import { useState } from "react"


export const ShopHome = () => {
    const [account, setAccount] = useState(1000)



    return (
        <div>
            <div className="mb-2">
                <ShopMainCarusel />
            </div>
            <Container>
                <h4>Колекция ep.#1</h4>
                <ProductSeasonSwiper />
                <h4>Колекция ep.#2</h4>
                <ProductSeasonSwiper />
            </Container>
        </div>
    )
}