import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const callApi = createApi({
    reducerPath: 'callApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'api',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().token.value;
            if (token) {
                headers.set("Authorization", `Bearer ${token.Token}`);
            }
            return headers;
        },
    }),
    tagTypes: ['User'],
    endpoints: (builder) => ({
        refreshToken: builder.mutation({
            query: (refTokrn) => ({
                url: '/api/login/refreshtoken',
                method: `POST`,
                body: refTokrn
            })
        }),
        checkToken: builder.query({
            query: () => 'users/checktoken'
        }),
        getUsers: builder.query({
            query: ({ pageIndex, pageSize, stringFilter }) => `users/getusers?pageIndex=${pageIndex}&pageSize=${pageSize}&stringFilter=${stringFilter}`,

            // query: ({pageIndex, pageSize, stringFilter}) => `users/getusers?pageIndex=${pageIndex}&pageSize=${pageSize}&stringFilter=${stringFilter}`,
        }),
        addUser: builder.query({
            query: () => ({
                url: 'adduser',
                method: 'POST',
                body: {},
            })
        }),
        getUserById: builder.query({
            query: (user_id) => `users/${user_id}`,
        }),
        getClientsByPhone: builder.mutation({
            query: (phone) => `clients/phone?phone=${phone}`,
        }),
        putUserById: builder.mutation({
            query: ({ id, ...form }) => ({
                url: `users/${id}`,
                method: 'PUT',
                body: form,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
                providesTags: ['User']
            }),

        }),
        chengePasswordUser: builder.mutation({
            query: (data) => (
                {
                    url: `users/chengePassword`,
                    method: `PUT`,
                    body: data,
                })
        }),
        addClientFromToble: builder.mutation({
            query: ({ ...form }) => ({
                url: `clients/addclientfromtable`,
                method: 'POST',
                body: form,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),

        }),
        getSkills: builder.query({
            query: () => `worksheets/getlistskills`
        }),
        postWorksheet: builder.mutation(
            {
                query: ({ ...form }) => ({
                    url: `worksheets`,
                    method: `POST`,
                    body: form,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    }
                })
            }
        ),
        createCertificateOrder: builder.mutation(
            {
                query: ({ ...body }) => ({
                    url: 'certificateorders',
                    method: 'POST',
                    body: body,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    }
                })

            }
        ),
        getCertOrders: builder.query({
            query: ({ pageIndex, pageSize, stringFilter }) => `certificateorders/?pageIndex=${pageIndex}&pageSize=${pageSize}&stringFilter=${stringFilter}`
        }),
        getCertOrderDetails: builder.query({ query: ({ id }) => `certificateorders/${id}` }),
        getOrders: builder.query({
            query: ({ pageIndex, pageSize, stringFilter }) => `orders/?pageIndex=${pageIndex}&pageSize=${pageSize}&stringFilter=${stringFilter}`
        }),
        getOrder: builder.query({ query: (id) => `orders/${id}` }),
        payOrder: builder.mutation({
            query: ({ ...body }) => ({
                url: `orders/payorder/${body.id}?typeOfPay=${body.typeOfPay}`,
                method: `GET`,
            })
        }),
        cancelOrder: builder.mutation({
            query: (id) => ({
                url: `orders/${id}/cancel`,
                method: `GET`,
            })
        }),
        getCategories: builder.query({ query: ({ pageIndex, pageSize, stringFilter }) => `categories/?${pageIndex && "&pageIndex=" + pageIndex}${pageSize && "&pageSize=" + pageSize}${stringFilter && "&stringFilter=" + stringFilter}` }),
        getCategory: builder.query({ query: (id) => `categories/${id}` }),
        putCategory: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `categories/${id}`,
                method: 'PUT',
                body: data,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
        }),
        getAgents: builder.query({ query: () => `agents` }),
        getAgent: builder.query({ query: (id) => `agents/${id}` }),
        getProducts: builder.query({
            query: ({ pageIndex, pageSize, stringFilter }) => `products/?pageIndex=${pageIndex}&pageSize=${pageSize}&stringFilter=${stringFilter}`
        }),
        getProductParams: builder.query({ query: () => `products/product-prams` }),
        addProduct: builder.mutation(
            {
                query: ({ ...form }) => ({
                    url: `products`,
                    method: `POST`,
                    body: form,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    }
                })
            }
        ),
        getProduct: builder.query({ query: (id) => `products/${id}` }),
        putProduct: builder.mutation(
            {
                query: ({ ...form }) => ({
                    url: `products/${form.id}`,
                    method: `PUT`,
                    body: form,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    }
                })
            }
        ),
        postProductPhoto: builder.mutation(
            {
                query: ({ id, ...form }) => ({
                    url: `products/photo/${id}`,
                    method: `POST`,
                    body: form,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    }
                })
            }
        ),
        deleteProductPhoto: builder.mutation(
            {
                query: (id) => ({
                    url: `products/deletephoto/${id}`,
                    method: `PUT`
                })
            }
        ),
        getCatalogProducts: builder.query({ query: () => `products/catalog/` }),
        createOrder: builder.mutation({
            query: (body) => ({
                url: `orders`,
                method: `POST`,
                body: body
            })
        }),
        getAgreementOrder: builder.mutation({
            query: (id) => ({
                url: `orders/agreement/${id}`,
                method: `GET`,
            })
        }),
        getClients: builder.query({ query: ({ pageIndex, pageSize, stringFilter }) => `clients/?pageIndex=${pageIndex}&pageSize=${pageSize}&stringFilter=${stringFilter}` }),
        getClientById: builder.query({ query: (id) => `clients/${id}` }),
        putClient: builder.mutation({query: ({...body}) => ({
            url: `clients/${body.id}`,
            method: "PUT",
            body: body
        })}),
        getEventType: builder.query({ query: () => `sessionevents/geteventtype`}),
        addSessionEvent: builder.mutation({query: (body) => ({
            url: `sessionevents`,
            method: 'POST',
            body: body
        })}),
        getSessionEvents: builder.query({ query: ({ pageIndex, pageSize, stringFilter }) => `sessionevents/?pageIndex=${pageIndex}&pageSize=${pageSize}&stringFilter=${stringFilter}`}),
        getSessionEventDetails: builder.query({ query: (id) => `sessionevents/${id}`}),
        postVisitorList: builder.mutation({query: ({body}) => ({
            url: `sessionevents/importlist`,
            method: `POST`,
            body: body,
            headers: {
                "Content-Type": "multipart/form-data;"
            }
        })}),
        getVisitorList: builder.query({query: ({id, stringFilter}) => `sessionevents/getvisitorlist/${id}`}),
        getVisitor: builder.query({query: (id) => `sessionevents/visitor/${id}`}),
        getReportIgora: builder.query({query: ({startDate, endDate}) => `reportsservice/getitemorderigora/?startDate=${startDate}&endDate=${endDate}`}),
        getShows: builder.query({query: () => `shows`}),
        createCorporateRequest: builder.mutation({query: ({...body}) =>({ 
            url: `requests/corporate`,
            method: `POST`,
            body: body,
        })}),
        createRentRequest: builder.mutation({query: ({...body}) =>({ 
            url: `requests/rent`,
            method: `POST`,
            body: body,
        })})

    }),

}

)

export const {
    useRefreshTokenMutation,
    useGetUsersQuery,
    useCheckTokenQuery,
    useGetUserByIdQuery,
    usePutUserByIdMutation,
    useAddClientFromTobleMutation,
    useChengePasswordUserMutation,
    useGetSkillsQuery,
    usePostWorksheetMutation,
    useCreateCertificateOrderMutation,
    useGetCertOrdersQuery,
    useGetCertOrderDetailsQuery,
    useGetOrdersQuery,
    useGetOrderQuery,
    useGetCategoriesQuery,
    useGetCategoryQuery,
    usePutCategoryMutation,
    useGetAgentsQuery,
    useGetAgentQuery,
    useGetProductsQuery,
    useGetProductParamsQuery,
    useAddProductMutation,
    useGetProductQuery,
    usePutProductMutation,
    usePostProductPhotoMutation,
    useDeleteProductPhotoMutation,
    useGetCatalogProductsQuery,
    useGetClientsByPhoneMutation,
    useCreateOrderMutation,
    useGetAgreementOrderMutation,
    useCancelOrderMutation,
    usePayOrderMutation,
    useGetClientsQuery,
    useGetClientByIdQuery,
    usePutClientMutation,
    useGetEventTypeQuery,
    useAddSessionEventMutation,
    useGetSessionEventsQuery,
    useGetSessionEventDetailsQuery,
    usePostVisitorListMutation,
    useGetVisitorListQuery,
    useGetVisitorQuery,
    useGetReportIgoraQuery,
    useGetShowsQuery,
    useCreateCorporateRequestMutation,
    useCreateRentRequestMutation,
} = callApi