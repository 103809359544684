import { PhotoAlbum } from "react-photo-album"
import { Container } from "reactstrap"

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";
import { useState } from "react";


export const LifeStyle = () => {
    const [index, setIndex] = useState(-1);
    const photos = [
        { src: "/img/model1.jpeg", width: 1066, height: 1600, href: "#", title: "Title 1", description: "Description 1 \n break test" },
        { src: "/img/about.jpeg", width: 1600, height: 900, href: "#", title: "Title 2", description: "Description 2" },
        { src: "/Front.jpeg", width: 1600, height: 1064, href: "#", title: "Title 3", description: "Description 3" },
        { src: "/img/model2.jpeg", width: 1066, height: 1600, href: "#" },
        { src: "/img/FCT-Model.jpeg", width: 1066, height: 1600, href: "#" },
        { src: "/img/model3.jpeg", width: 1066, height: 1600, href: "#" },
        { src: "/Front.jpeg", width: 1600, height: 1064, href: "#" },
    ]

    return (
        <div>
            <Container className="mt-3 mb-3">
                <PhotoAlbum columns={3} layout="masonry" photos={photos} onClick={({ index }) => setIndex(index)} />
            </Container>
            <Lightbox
                slides={photos}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                // enable optional lightbox plugins
                plugins={[Slideshow, Thumbnails, Zoom, Captions]}
            />
        </div>
    )
}