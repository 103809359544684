import { Autoplay, EffectFade, Navigation, Pagination, Zoom } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from "swiper/react";
import { ConstructionProgress } from "../ConstructionProgress";
import { useEffect, useState } from "react";


export const ShopMainCarusel = () => {

    const [delaySlide, setDelaySlide] = useState()
    const [indexSlideActive, SetIndexSlideActive] = useState()

    return (
        <Swiper
            spaceBetween={0}
            slidesPerView={1}
            // effect={"fade"}
            navigation={true}
            
            loop={true}
            // onSwiper={(swiper) => console.log(swiper)}
            // zoom={true}
            // onSlideChange={(swiper) => {console.log(swiper)}}
            autoplay={{ delay: 10000, disableOnInteraction: false, pauseOnMouseEnter: true }}
            pagination={{
                clickable: true,
            }}
            modules={[EffectFade, Navigation, Pagination, Autoplay, Zoom]}
            className="mySwiper"
        >
            <SwiperSlide>
                <div className="cover">
                    <div className='cover-img' style={{ backgroundImage: `url("/Front.jpeg")` }}>
                    </div>
                    <div id="container-front">
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="cover">
                    <div className='cover-img' style={{ backgroundImage: `url("/img/about.jpeg")` }}>
                    </div>
                    <div id="container-front">
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="cover">
                    <div className='cover-img' style={{ backgroundImage: `url("/Front.jpeg")` }}>
                    </div>
                    <div id="container-front">
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="cover">
                    <div className='cover-img' style={{ backgroundImage: `url("/img/about.jpeg")` }}>
                    </div>
                    <div id="container-front">
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    )
}